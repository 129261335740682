<template>
  <v-container>
    <SuccessAlert :timeout="timeout" :model="isSuccess" :condition="isSuccess"/>


    <v-card>
      <v-card-title>
          <span class="headline">Créer un nouveau client  {{welcomeMessage}}
</span>
      </v-card-title>
      <v-card-text>
        <CustomerForm :customer="customer" :validation-errors="validationErrors"/>
        <small>*champs requis</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="$emit('close-dialog')">
          Fermer
        </v-btn>
        <v-btn
            color="blue darken-1"
            dark
            rounded
            @click="submitCustomer(customer)">
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>

</template>

<script>
import SuccessAlert from "@/components/SuccessAlert";
import CustomerForm from "@/views/client/CustomerForm";
import customerValidationErrorsFields from "@/form/customer-validation-errors-fields";
export default {
  name: "NewCustomer",
  components: {CustomerForm, SuccessAlert},
  props: {
    welcomeMessage: null
  },
  data : function() {
    return {
      modal: null,
      formDialog: false,
      loading: false,
      isSuccess: false,
      successMessage: null,
      timeout: 30000,

      customer: {
        prenom: null,
        nom: null,
        phoneNumber: null,
        sexe: null,
      },
      validationErrors: customerValidationErrorsFields
    };
  },
  methods: {
    submitCustomer(customer){
      Object.keys(this.validationErrors).forEach((key) =>{ this.validationErrors[key].error = false;  this.validationErrors[key].message = null });
      this.$axios.post("clients", customer)
          .then((response) =>{
            if (response.isSuccessCreated()){
              this.showSuccessAlert("Client crée avec succès !", ()=>{
                this.$emit("close-dialog")
              })}

          }).catch( (error) =>{
        let response = error.response
        if (response.isUnprocessableEntity()){
          response.mapValidationErrorsToFields(this.validationErrors,{})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
