<template>
  <div class="text-center">
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon  color="error"
                       @click='exportBookings(depart,"text",false)' >
                mdi-file

              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Exporter Non payés Texte</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon color="success" @click='exportBookings(depart,"text",true)'>
                mdi-file
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Exporter  payés Texte</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="success"  @click='exportBookings(depart,"pdf",true)'>
                mdi-file-pdf-box
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Exporter  payés PDF</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="red"  @click='exportBookings(depart,"pdf",false)'>
                mdi-file-pdf-box            </v-icon>
            </v-list-item-action>
            <v-list-item-title>Exporter Non payés PDF</v-list-item-title>
          </v-list-item>
        </v-list>
  </div>

</template>

<script>
import BookingsExporter from "@/utils/BookingsExporter";

export default {
name: "ExportDepartBookings",
  props:{
  depart: {type: Object, required: true}
  },
  data:()=>({
    menu: false
  }),
  methods:{
    exportBookings(depart, format, paye){
      this.$axios.get("departs/:id/bookings".replace(":id",depart.id))
          .then(response =>{
            if (response.isSuccessful()){
              let bookings = response.getData();
              if (paye){
                bookings = bookings.filter(booking => booking.hasTicket)
              }else{
                bookings = bookings.filter(booking => ! booking.hasTicket)
              }
              let exporter = new BookingsExporter()
              if (format === "text")
                exporter.exportBookingsAsText(bookings, "liste-des-inscrits-:bus".replace(":bus",depart.name))
              else if(format === "pdf")
                exporter.exportBookingsAsPDF(bookings, "liste-des-inscrits-:bus".replace(":bus",depart.name),"Liste des inscrits départ-:bus".replace(":bus",depart.name))
            }
          })
    },
  }
}
</script>

<style scoped>

</style>
