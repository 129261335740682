<template>
<v-card>
  <v-card-title>Transférer des réservations</v-card-title>
  <v-card-subtitle>Vous allez transférer les réservations du bus "{{ bus.fullName }}"</v-card-subtitle>
  <v-card-text>
    <h1> </h1>
    <v-form>
      <v-autocomplete v-model="transfer.targetBus" label="Transférer vers quel bus ?" hint="Bus de destination" :items="activeBuses" item-value="@id" item-text="fullName"></v-autocomplete>
      <v-radio-group @change="onTypeOfTransferChosen()" v-model="transfer.transferType" label="Quel type de réservations transférer ?">
        <v-radio label="Réservations non payées" value="1"></v-radio>
        <v-radio label="Réservations payées" value="2"></v-radio>
        <v-radio label="Toutes les réservations (Payées & Non)" value="3"></v-radio>
      </v-radio-group>
      <v-text-field type="number" label="Nombreà transférer " hint="Saisir le nombre à transférer " v-model="transfer.numberOfBookingsToTransfer" v-if="numberOfBookingsToTransferToggle" :messages="['-1 signifie toutes les réservations seront transférées']"></v-text-field>
      <v-btn width="100%" color="success" @click="submitTransfer">Transférer</v-btn>
    </v-form>
  </v-card-text>
  </v-card>
</template>

<script>
export default {
name: "TransferBusBookings",
  props: {
  bus: {type: Object, required: true}
  },
  data() {
    return {
      activeBuses: [],
      transfer:{
        transferType: 2,
        numberOfBookingsToTransfer: -1,
        sourceBus: this.bus['@id'],
        targetBus: null,
      },
      numberOfBookingsToTransferToggle: false
    }
  },
  methods: {
    onTypeOfTransferChosen() {
      this.numberOfBookingsToTransferToggle = this.transfer.transferType !== "3";
    },
    submitTransfer() {
      this.transfer.transferType =  parseInt(this.transfer.transferType)
      this.$axios.put("buses/:id/transfer_bookings".replace(":id", this.bus.id), this.transfer)
      .then(response => {
        if (response.isSuccessful()){
          this.showSuccessAlert("Réservations transférées avec succès !")
        }
      })
    }
  },
  mounted() {
  this.$axios.get("buses")
    .then(response => {
      if (response.isSuccessful()){
        this.activeBuses = response.getData()
      }
    })
  }
}
</script>

<style scoped>

</style>