<template>
  <div>
    <v-alert v-model="generalError.error" type="error">{{generalError.message}}</v-alert>
    <v-form>
      <v-text-field v-if="!isMultiple" required type="text" label="Nom du départ" v-model='depart.name'
                     :error="validionsFields.name.error" :error-messages="validionsFields.name.message" />
      <v-text-field  v-if="!isMultiple" required type="date" label="Date" v-model='depart.date'
                     :error="validionsFields.date.error"
                     :error-messages="validionsFields.date.message" />
      <v-select required label="Horaire" v-model.number='depart.horaire' :error="validionsFields.horaire.error" :error-messages="validionsFields.horaire.message"
                :value="value" :items="[
                    {text: 'Matin UGB-DAKAR', value:4},
                    {text: 'Nuit UGB-DAKAR', value:1},
             {text: 'Après-midi UGB-DK', value: 2},
             {text: 'Matin DAKAR-UGB', value: 3},
             {text: 'Après-midi DAKAR-UGB', value: 5},
             {text: 'Nuit DAKAR-UGB', value: 6},
             ]" item-text="text">
      </v-select>
<!--      <v-select  required label="Visibilité" v-model.number='depart.visibilite' -->
<!--                 :error="validionsFields.visibilite.error" :error-messages="validionsFields.visibilite.message"-->
<!--                :items="[{text: 'Au personnel', value:1},{text: 'Tout le monde', value: 3}]"-->
<!--                item-value="value" item-text="text"></v-select>-->
      <v-select required label="Évènement" v-model='depart.event' :loading="isLoadingEvents" :error="validionsFields.event.error" :error-messages="validionsFields.event.message"
                item-value="@id" :items="events"  item-text="name"></v-select>
      <v-select label="Trajet" v-model='depart.trajet' required :loading="isLoadingTrajets" :error="validionsFields.trajet.error" :error-messages="validionsFields.trajet.message"
                item-value="@id" :items="trajets" item-text="name">
      </v-select>
    </v-form>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  name: "DepartForm",
  props: {
    depart: {
      type:Object,
      required: true
    },
    generalError: {
      type:Object,
      required: false
    },
    validionsFields: {
      type:Object,
      required: false
    },
    isLoadingEvents: Boolean,
    isLoadingTrajets: Boolean,
    events:Array,
    trajets:Array,
    isMultiple:{
      type:Boolean,
      default: false
    }
  }
}
</script>