<template>

  <v-card class="col-md-6 col-sm-12">
    <v-card-text>
      <v-alert type="error" v-model="generalError.error">{{ generalError.message }}</v-alert>
      <bus-form :depart="depart" :bus="bus" @submit="submitNewBus" :validions-fields="validionsFields"/>
    </v-card-text>
  </v-card>
</template>

<script>
import BusForm from "@/views/bus/BusForm";
export default {
  name: "NewBus",
  components: {BusForm},
  props: {
    depart: {
      type: Object,
      // required: true,
    }
  },
  data() {
    return {
      bus: {
        depart: null,
        name: "Bus " + (this.depart.buses.length +1),
        seatCount: 57,
        ticketPrice: 3550,
        vehicle: 1
      },
      generalError: {
          error: false,
          message: null
        },
        validionsFields: {
        name: {
          error: false,
          message: null
        },
        ticketPrice: {
          error: false,
          message: null
        },
        seatCount: {
          error: false,
          message: null
        },
          vehicle: {
          error: false,
          message: null
        },
      }
    }

  },
  methods: {
    submitNewBus(){
      this.$axios.post("buses", this.bus)
      .then(response => {
        if(response.isSuccessCreated()){
          this.$store.dispatch("loader/showIsSavedSuccessfully")
          this.depart.buses.push(this.bus)
        }
      }).catch(error => {
        if (error.response.isUnprocessableEntity()){
          error.response.mapValidationErrorsToFields(this.validionsFields, this.generalError)
        }

      })

    }
  },
  mounted() {
    this.bus.depart = this.depart["@id"]
  }
}
</script>

<style scoped>

</style>
