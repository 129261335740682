<template>
<v-col>
  <v-card min-width="100%">
      <v-card-title>
        <span class="headline"></span>
      </v-card-title>
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="col-lg-12 col-sm-12 col-md-12">
            <h2>Liste des inscrits {{bus.fullName}}</h2>
            <BookingsDataTable sort-field="seatNumber" :bookings="bookingsList" :is-loading-bookings="isLoadingBookings" :depart="depart" :bus="bus"  :headers="headers" :should-show-seat-numbers="bus.shouldShowSeatNumbers" />
          </v-col>
          </v-row>
      </v-card-text>

    </v-card>
</v-col>
</template>

<script>
// import axios from "axios";
import BookingsDataTable from "@/views/booking/BookingsDataTable";
export default {

name: "Bookings",
  components: {BookingsDataTable},
  props:{
   bus:{
    type: Object
  },
    depart:{
    type: Object
  }
  },
  data:() =>({
    isLoadingBookings: true,
    bookingsList: [
    ],
    dialog: null
}),
  computed: {
  // listTitle: this.bus.name + this.depart.name,
    headers () {
      return [
        {
          text: 'Numéro',
          align: 'start',
          sortable: true,
          value: 'seatNumber',
        },{
          text: 'Client',
          align: 'start',
          sortable: false,
          value: 'client.fullName',
        },
        {
          text: 'Téléphone',
          value: 'client.phoneNumber'
        },
        { text: 'Destination', value: 'destination.name' },
        { text: 'P. départ', value: 'pointDep.name' },
        // // { text: 'Inscrit par', value: 'bookedBy' },
        { text: 'Payé par', value: 'ticketSoldBy' },
        { text: 'Actions', value: 'actions' },

      ]
    },
  },
  methods: {
    loadBookings() {
      this.$axios.get("buses/" + this.bus.id +"/bookings")
          .then((response) => {
               this.bookingsList = response.getData();
          }).finally(()=>{
        this.isLoadingBookings = false

      })
    }
  },
  mounted() {
    this.loadBookings()
  }
}
</script>

<style scoped>

</style>
