<template>
<v-row>
  <v-col>
    <h4>Transférer cette réservation de {{booking.client.fullName}} vers un autre bus</h4>
    <v-select :items="buses" item-value="@id" item-text="fullName" label="Transférer vers ?"
              hint="Sélectionner le bus de destination " v-model="booking.bus"
    :loading="isLoadingBuses">
    </v-select>
    <v-alert type="error" v-if="hasErrors">
      <p>{{ flattenValidationErrors }}</p>
    </v-alert>
    <v-btn outlined color="info" @click="transferBooking()">Transférer</v-btn>
  </v-col>
</v-row>
</template>

<script>
import bookingValidationErrorsFields from "@/form/booking-validation-errors-fields";
export default {
  name: "TransferBooking",
  props: {
    booking: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      buses: [],
      validationErrors: bookingValidationErrorsFields,
      hasErrors: false,
      flattenValidationErrors: null,
      isLoadingBuses: true
    }
  },
  mounted() {
    this.$axios.get("buses")
        .then(response => {
          if (response.isSuccessful()) {
            this.buses = response.getData()
          }
        }).finally(()=>{
          this.isLoadingBuses = false
    })
  },
  methods: {
    transferBooking() {
      this.hasErrors = false
      let data = Object.assign({}, this.booking)
     delete data.client
     delete data.destination
     delete data.pointDep
     delete data.seatNumber
     delete data.hasTicket
     delete data.hasSeat
      this.$axios.put("bookings/:id/transfer".replace(":id", this.booking.id),data)
          .then(response => {
            if (response.isSuccessful()) {
              this.showSuccessAlert("Réservation transférer avec succès !")
            }
          }).catch(error => {
            let response = error.response
        if (response.isUnprocessableEntity()){
          this.hasErrors = true
          this.flattenValidationErrors = response.getFlattenValidationErrors()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
