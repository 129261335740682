<template>
  <v-row>

    <v-card>
      <v-card-title>
        <span class="text-center" v-if="selected.length > 0">Total sélectionné &nbsp;&nbsp; {{ totalSelected }}</span >
        <span class="text-center" v-else>Sélectionnez des points pour compter leur nombre</span >
      </v-card-title>
      <v-card-text>
        <v-data-table
            :items="groupings"
            :headers="headers"
            v-model="selected"
            :single-select="false"
            item-key="total"
            show-select
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "BookingsGrouping",
  props: {
    depart: {
      type: Object, required: true
    }
  },
  data:()=>({
    totalSelected: 0,
    selected: [],
    groupings:[],
    headers: [
      {text: "Point de départ", value: "nom"},
      {text: "Nombre inscrit", value: "bookingsCount"},
      {text: "Total au point", value: "total"},
    ]
  }),
  watch: {
    selected(newValue) {
      let total = 0;
      for (let i = 0; i < newValue.length; i++) {
        total = total + newValue[i].bookingsCount
      }
      this.totalSelected = total
    }
  },
  mounted() {

    this.$axios.get("departs/:id/bookings_grouping".replace(":id", this.depart.id))
    .then(response =>{
      if (response.isSuccessful()){
        let totalCurrent = 0;
        this.groupings = response.getData()
        for (let i = 0; i < this.groupings.length; i++) {
          totalCurrent = totalCurrent + this.groupings[i].bookingsCount
          this.groupings[i]["total"]= totalCurrent
        }
      }

    })
  }
}
</script>

<style scoped>

</style>
