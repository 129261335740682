<template>

  <div>
    <v-form>
      <v-text-field required type="text" label="Nom du Bus" v-model='bus.name' :error="validionsFields.name.error" :error-messages="validionsFields.name.message"/>
      <v-text-field required type="number" label="Nombre de place" v-model.number='bus.seatCount' :error="validionsFields.seatCount.error" :error-messages="validionsFields.seatCount.message"/>
      <v-text-field required type="number" label="Prix Ticket" v-model.number='bus.ticketPrice' :error="validionsFields.ticketPrice.error" :error-messages="validionsFields.ticketPrice.message"/>
<!--      select vehicule-->
      <v-select required label="Véhicule transport" v-model.number='bus.vehicle'
                value="value" :items="[
                    {text: 'Bus G 57 places', value:1},
                    {text: 'Bus climatisé mini model', value:2},
             {text: 'Bus climatisé Grand Model', value: 3}
             ]" item-text="text">
      </v-select>
      <v-btn  @click="$emit('submit')"  background-color="success" value="Enregistrer" rounded color="success" class="left">Enregistrer</v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "BusForm",
  props: {
    depart: {
      type: Object,
      // required: true,
    },
    bus: {
      type: Object,
      required: true,
    },
    validionsFields: {
      type: Object
    }
  },
  data() {
    return {
      generalError: {
          error: false,
          message: null
        },
      //   validionsFields: {
      //   name: {
      //     error: false,
      //     message: null
      //   },
      //   ticketPrice: {
      //     error: false,
      //     message: null
      //   },
      //   seatCount: {
      //     error: false,
      //     message: null
      //   },
      // }
    }

  }
}
</script>

<style scoped>

</style>