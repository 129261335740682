<template>
<v-row>
  <v-col>
    <v-data-table :items="pointDeparts" :headers="headers" no-data-text="Aucun élément" disable-pagination>
      <template v-slot:item.action="{ item }">
        <v-btn  @click="update(item)" text  color="primary"><v-icon>mdi-pencil</v-icon>Modifier</v-btn>
        <v-btn  @click="toggle(item)" text><v-icon v-if="!item.disabled" color="red">mdi-lock</v-icon><v-icon color="success" v-else>mdi-lock-open</v-icon>{{getToggleLabel(item)}}</v-btn>
      </template>
    </v-data-table>
    <v-dialog
        v-model="departActionDialog"
        width="50%"
    >
      <v-card>
        <component :is="departActionComponent" v-bind="departActionComponent" :key="renderKey"/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="departActionDialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-col>
</v-row>

</template>

<script>
import EventItemActions from "@/views/event/EventItemActions";
import PointDepartEdit from "@/views/point_depart/PointDepartEdit";
export default {
name: "PointDepartsList",
  components: {EventItemActions},
  data: () => ({
    pointDeparts: [

    ],
    headers: [
      {
        text: "Nom",
        value: "name"
      },{
        text: "Arrêt bus",
        value: "arretBus"
      },{
        text: "Heure matin",
        value: "departureSchedule"
      },
      {
        text: "Heure soir",
        value: "departureScheduleEvening"
      },{
        text: "Actions",
        value: "action"
      }
    ],
    renderKey: null,
    departActionDialog: null,
    departActionComponent: null
  }),
  mounted() {
    this.$axios.get("point_departs")
        .then(response => {
          if (response.isSuccessful()) {
            this.pointDeparts = response.getData();
          }

        })
  },
  methods: {
    getToggleLabel(item) {
      return !item.disabled ? "Réactiver": "Désactiver"
    },
    update(item) {
        this.renderKey++
        this.departActionDialog = true
        this.departActionComponent = {
          is: PointDepartEdit,
          pointDepart: item
        }


    },
    toggle(item) {
      this.showAlertConfirm({confirmationMessage:"Voulez vous effectuer cette action ?",okButton:"Valider"},()=>{
        let url = "point_departs/:id".replace(":id", item.id)
        this.$axios.put(url, {disabled: !item.disabled})
            .then(response => {
              if (response.isSuccessful()) {
                this.showSuccessAlert("Modifications enregistrées avec succès")
              }

            }).catch(error => {
          let response = error.response
          if (response.isUnprocessableEntity()) {
            response.mapValidationErrorsToFields(this.validionsFields, this.generalError)
          }
        });
      })


      ////
    }
  }
}
</script>

<style scoped>

</style>
