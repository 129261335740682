"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.API_BASE_URL;
// axios.defaults.baseURL = "http://localhost/";
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
export const SERVER_BASE_URL = process.env.NODE_ENV === "development"?'http://Localhost:8888/Transport/GoTransportBackend/public/api/':'https://golobone.net/go_travel_v4/public/'
// export const API_BASE_URL = process.env.NODE_ENV === "development"?'http://Localhost:8888/Transport/GoTransportBackend/public/api/':'https://golobone.net/go_travel_v4/public/api/'
export const API_BASE_URL = process.env.NODE_ENV === "development"?'https://senapel.com/api/':'https://senapel.com/api/'
let config = {
  baseURL: API_BASE_URL
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};
export const _axios = axios.create(config);
// _axios.defaults.baseURL  = process.env.baseURL || process.env.apiUrl || '';

/*_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);*/

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
