<template>
  <v-chip  :disabled="seat.booked" @click="$emit('on-seat-click')" :color="seat.booked ? 'red' : 'success'" :content="seat.number" pill text ><v-icon color="indigo" large>mdi-seat-passenger</v-icon> {{ seat.number }} </v-chip>
</template>

<script>
export default {
name: "SeatNumberRenderer",
  props: {
  seat: {
    type: Object,
    required: true
  }
  }
}
</script>

<style scoped>

</style>