<template>
  <v-col>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            color="indigo"
            dark
            v-bind="attrs"
            v-on="on">
           mdi-dots-horizontal
         </v-icon>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-car</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{bus.name}}</v-list-item-title>
              <v-list-item-subtitle>{{departure.name}}</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-action>
             <v-icon  color="purple"
                        @click="showBusTicketSales(bus)" >mdi-cash-multiple
             </v-icon>
            </v-list-item-action>
            <v-list-item-title>Chiffres</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isGranted('ROLE_EMPLOYEE')">
            <v-list-item-action>
             <v-icon  color="purple"
                        @click="showBusSeats(bus)" >mdi-seat
             </v-icon>
            </v-list-item-action>
            <v-list-item-title>Sièges Bus</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                  @change="onCloseBookingsSwitch(bus, $event)"
                  color="purple"
                  v-model='bus.closed'
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Clôturer réservations</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isGranted('ROLE_OWNER')">
            <v-list-item-action v-if="isGranted('ROLE_OWNER')">
              <v-switch
                  @change="onShowSeatNumbersSwitch(bus, $event)"
                  color="purple"
                  v-model='bus.shouldShowSeatNumbers'
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Numéros sièges visibles</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon color="indigo" @click="openEditForm(bus)">
                mdi-pencil
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Modifier infos bus</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="indigo" @click="openBusBookingsTransferForm(bus)">
                mdi-forward
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Transférer les réservations</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="red" @click="onDeleteBusClicked(bus)">
                mdi-close-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Supprimer le Bus</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-btn color="success" @click='exportBookings(bus,"pdf",true)' text>
                <v-icon left>
                  mdi-file-pdf-outline
                </v-icon>
                Payés PDF
              </v-btn>
              <v-btn  color="success" @click='exportBookings(bus,"pdf",false)' text >
                <v-icon left>
                  mdi-file-pdf-outline

                </v-icon>
                Non Payés PDF
              </v-btn><v-btn  color="indigo" @click='exportBookings(bus,"text",true)' text >
                <v-icon left>
                  mdi-file
                </v-icon>
              Payés Text
              </v-btn><v-btn  color="indigo" @click='exportBookings(bus,"text",false)'  text>
                <v-icon left>
                  mdi-file

                </v-icon>
                Non Payés Text
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="menu = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-dialog
        v-model="formDialog"

        width="90%"
    >
      <component :is="ticketSalesComponent" v-bind="ticketSalesComponent" :key="ticketSalesComponentRenderKey"/>

      <v-card>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="formDialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="transferBookingDialog"
        content-class="col-md-6 col-sm-12"
    >
      <component :is="transferBookingsComponent" v-bind="transferBookingsComponent" :key="transferBookingsRenderKey"/>

      <v-card>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="transferBookingDialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<v-dialog
        v-model="editBusDialog"
        content-class="col-md-6 col-sm-12"
    >
      <component :is="editBusComponent" v-bind="editBusComponent" :key="editBusRenderKey"/>

      <v-card>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="editBusDialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-col>
</template>

<script>
import TicketSales from "@/views/ticket_sales/TicketSales";
import BookingsExporter from "@/utils/BookingsExporter";
import TransferBusBookings from "@/views/bus/TransferBusBookings";
import EditBus from "@/views/bus/EditBus";
import BusSeats from "@/views/bus/BusSeats";

export default {
name: "BusActions",
  comments: [
      TicketSales,
      TransferBusBookings
  ]
  ,
  props:{
  bus: {
    type: Object
  }, departure: {
    type: Object
  }
  },
  data: () => ({
    busClosed: false,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    formDialog: false,
    ticketSalesComponent: null,
    ticketSalesComponentRenderKey: 0,
    transferBookingDialog: false,
    transferBookingsComponent: null,
    transferBookingsRenderKey: 0,
    editBusDialog: false,
    editBusComponent: null,
    editBusRenderKey: 0,
  }),
  methods: {
    showBusTicketSales(bus) {
      this.formDialog = true
      this.ticketSalesComponentRenderKey++
      this.ticketSalesComponent = {
        is: TicketSales,
        bus: bus,
      }
    },
    exportBookings(bus, format, paye){
      this.$axios.get("buses/:id/bookings".replace(":id",bus.id))
      .then(response =>{
        if (response.isSuccessful()){
          let bookings = response.getData();
          if (paye){
            bookings = bookings.filter(booking => booking.hasTicket)
          }else{
            bookings = bookings.filter(booking => ! booking.hasTicket)
          }
          let exporter = new BookingsExporter()
          if (format === "text")
          exporter.exportBookingsAsText(bookings, "liste-des-inscrits-:bus".replace(":bus",bus.name))
          else if(format === "pdf")
          exporter.exportBookingsAsPDF(bookings, "liste-des-inscrits-:bus".replace(":bus",bus.name),"Liste des inscrits -:bus".replace(":bus",bus.name))
        }
      })
    },

    onCloseBookingsSwitch(bus, event) {
      let data = {
        closed: event
      }
      this.$axios.put("buses/:id".replace(":id", bus.id),data)
      .then(response => {
        if (response.isSuccessful()){
          bus.closed = event
        }
      })
    },
    openBusBookingsTransferForm(bus) {
      this.transferBookingDialog = true
      this.transferBookingsRenderKey++
      this.transferBookingsComponent = {
        is: TransferBusBookings,
        bus: bus,
      }
    },
    openEditForm(bus) {
      this.editBusDialog = true
      this.editBusRenderKey++
      this.editBusComponent = {
        is: EditBus,
        bus: bus,
      }
    },
    onDeleteBusClicked(bus) {

      this.showAlertConfirm({okButton: "Supprimer",confirmationMessage: "Voulez-vous vraiment supprimer le bus " + bus.fullName}, ()=>{
        this.$axios.delete("buses/:id".replace(":id", bus.id))
            .then(response => {
              if (response.isDeletedSuccessfully()){
                this.showSuccessAlert("Le bus " + bus.fullName + " a été supprimé avec succès !")
              }
            })
      })
    },
    showBusSeats(bus) {
      this.formDialog = true
      this.ticketSalesComponentRenderKey++
      this.ticketSalesComponent = {
        is: BusSeats,
        bus: bus,
        depart: this.departure,
      }
    },
    onShowSeatNumbersSwitch(bus, $event) {
      let data = {
        shouldShowSeatNumbers: $event
      }
      this.$axios.put("buses/:id".replace(":id", bus.id),data)
          .then(response => {
            if (response.isSuccessful()){
              bus.shouldShowSeatNumbers = $event
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
