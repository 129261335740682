<template>
  <div>
    <v-checkbox @change="createMultiple = !createMultiple" label="Créer plusieurs départs" color="success"/>
    <div>
      <v-alert v-model="generalError.error" type="error">{{ generalError.message }}</v-alert>
      <DepartForm :depart="depart" :is-multiple="createMultiple" :events="events" :trajets="trajets"
                  :is-loading-events="isLoadingEvents"
                  :is-loading-trajets="isLoadingTrajets" :general-error="generalError"
                  :validions-fields="validionsFields"/>
      <template  v-if="createMultiple">
        <v-text-field v-model="departNameTemplate" label="Template"></v-text-field>
        <v-select v-model="selectedDepartureSchedules" multiple chips :items="departureSchedules"
                   clearable label="Dates multiples"></v-select>
      </template>
    </div>
    <v-divider ></v-divider>

    <v-btn @click="submitNewDeparture" outlined color="success">Enregistrer</v-btn>

  </div>
</template>
<script>
// import axios from "axios";
import {clearFormErrors} from "@/form/validation"
import DepartForm from "@/views/depart/DepartForm";
import moment from "moment/moment";
export default {
  name: "DepartureNew",
  components: {DepartForm},
  data(){
    return{
      isLoadingEvents: true,
      isLoadingTrajets: true,
      createMultiple: false,
      departureSchedules: [],
      selectedDepartureSchedules: [],
      multipleDeparts: [],
      departNameTemplate: 'Départ <Date>',
      dateStart: moment(),
      depart: {
        name: null,
        date: null,
        event: null,
        trajet: null,
        visibilite: 3,
        horaire: null
      },
      events:[
      ],
      trajets:[
      ],

      generalError: {
        error: false,
        message: null
      },
      validionsFields: {
        name: {
          error: false,
          message: null
        },
        date: {
          error: false,
          message: null
        },
        event: {
          error: false,
          message: null
        },horaire: {
          error: false,
          message: null
        },trajet: {
          error: false,
          message: null
        }, visibilite: {
          error: false,
          message: null
        },
      }
    }

  },
  methods:{
    addDepartOnMultiple(){
      this.multipleDeparts.unshift(Object.assign({},this.depart))
    },
    addDepartureSchedule(schedule){
      this.departureSchedules.push(schedule)
    },
    generateMultipleDeparts(){
      if (this.selectedDepartureSchedules.length > 0) {
        for (let i = 0; i < this.selectedDepartureSchedules.length; i++) {
          moment.locale('FR')
          let departName = this.departNameTemplate.replaceAll("<Date>",
              moment(this.selectedDepartureSchedules[i]).format("dddd DD MMMM"))
          let date = this.selectedDepartureSchedules[i]
          let dep = Object.assign({}, this.depart)
          dep.date = date
          dep.name = departName
          this.multipleDeparts.push(dep)
        }
      }

    },

    loadEvents(){
      this.$axios.get("events")
          .then(response => {
            this.isLoadingEvents = false
            this.events = response.getData()
            this.loadDirections()
          }).catch(() => {
        this.isLoadingEvents = false
      })
    },
    loadDirections(){
      this.$axios.get("trajets")
          .then(response => {
            this.isLoadingTrajets = false
            this.trajets = response.getData()
          }).catch(() => {
        this.isLoadingTrajets = false
      })
    },
    submitNewDeparture(){
      clearFormErrors(this.validionsFields)
      this.generateMultipleDeparts()
      let url = 'departs';
      let data =this.depart
      if (this.createMultiple){
        url = "departs/create_multiple";
        data = {departs: this.multipleDeparts}
      }
      this.$axios.post(url, data)
          .then(response => {
            if (response.isSuccessCreated()) {
              this.showSuccessAlert("Départ crée avec succès",()=>{
                this.$emit("close-dialog")
              })
              this.$root.$emit("depart_created",response.getData())
            }


          }).catch(error => {
        let response = error.response
        if (response.isUnprocessableEntity()){
          response.mapValidationErrorsToFields(this.validionsFields, this.generalError)
        }
      })

    }
  },
  mounted() {
    moment.locale("FR")
    // add 30 days to the current date
    const  numberOfDaysPassed = 60
    this.addDepartureSchedule({'text':moment().format('LLLL'), value: moment().format("YYYY-MM-DD HH:mm:ss")})

    for (let i = 1; i <= numberOfDaysPassed; i++) {
      let schedule = moment().add(i,'days')


      this.addDepartureSchedule({'text':moment(schedule).format('LLLL'), value: moment(schedule).format("YYYY-MM-DD HH:mm:ss")})
    }
    this.loadEvents()

  }
}
</script>
