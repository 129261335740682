<template>
  <div>
    <v-system-bar
        window
        dark
        fixed
        color="info" app
    >
      <v-menu
          open-on-hover
          bottom
          offset-y
          v-for="(menu, index) in menuItems"
          :key="index"
      >
          <template v-slot:activator="{ on, attrs }" v-if="isGranted(menu.role)">
            <v-btn class="white-text" text dark v-bind="attrs" v-on="on">
              <v-icon left>{{ menu.icon }}</v-icon>
              <span>{{ menu.title }}</span>
            </v-btn>
          </template>

          <v-list v-if="isGranted(menu.role)">
            <template  v-if="isGranted(menu.role)">
              <v-list-item
                  v-for="(item, index) in menu.items"
                  :key="index" link

              >
                <v-list-item-title v-if="isGranted(item.role)" @click="onMenuItemClicked(item)">{{
                    item.title
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-text-field v-if="isGranted('ROLE_EMPLOYEE')"
                    hint="Rechercher un client"
                    v-model="searchValue"
                    @input="findCustomer()"
                    color="indigo"
                    placeholder="Rechercher un client "
                    prepend-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>
      <span>Solde Compte</span><v-spacer></v-spacer><span class="title">{{balance.toLocaleString()}}</span>
      <v-btn @click="showBalanceOfUpcoming()" text><v-icon>mdi-refresh</v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-menu
          open-on-hover
          bottom
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="white-text" text dark v-bind="attrs" v-on="on">
            <v-icon left>mdi-account</v-icon>
            <span>{{user.username}}</span>
          </v-btn>
        </template>

        <v-list>
            <v-list-item>
              <v-list-item-title><v-btn text @click="updatePassword()"><v-icon right>mdi-pencil</v-icon>Changer Mot de passe</v-btn>

              </v-list-item-title>
            </v-list-item><v-list-item>
              <v-list-item-title>      <v-btn text @click="logoutUser()"><v-icon right>mdi-logout</v-icon>Déconnexion</v-btn>

              </v-list-item-title>
            </v-list-item>
        </v-list>
      </v-menu>

    </v-system-bar>
    <v-dialog v-model="modalToShowMenuContent" @click:outside="modalToShowMenuContent = false">
      <v-card>
        <v-card-text>
          <component :is="componentToShow" :key="itemKey" @close-dialog="onDialogClose()"></component>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalToShowCustomerDetail" @click:outside="modalToShowCustomerDetail = false">
      <v-card>
        <v-card-text>
          <CustomerDetail :customer="foundCustomer" :key="itemKey"></CustomerDetail>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {eventBus} from "@/main";
import DepartureNew from "@/views/depart/DepartureNew";
import NewCustomer from "@/views/client/NewCustomer";
import CustomerDetail from "@/views/client/CustomerDetail";
import NewEvent from "@/views/event/NewEvent";
import EventsList from "@/views/event/EventsList";
import RevenuReports from "@/views/event/RevenuReports";
import {isValidPhoneNumber} from "@/utils/phone_number_validator"
import NewEmployee from "@/views/employee/NewEmployee";
import EmployeeList from "@/views/employee/EmployeeList";
import * as routes from "@/constants/routeConstants.ts";
import * as userRoles from "@/constants/userRoles.ts"
import {mapActions} from 'vuex'
import {sum_array} from "@/utils/sum_array";
// import Pusher from "pusher-js";

export default {
  name: "AppBarMenu",
  components: {
    CustomerDetail,
    DepartureNew,
    NewEvent,
    EventsList,
    RevenuReports,
    EmployeeList,
    NewCustomer, NewEmployee},
  data: ()=>({
    modalToShowMenuContent: false,
    componentToShow: null,
    showNewDepartureForm: false,
    showNewEventForm: false,
    showNewCustomerForm: false,
    balance:0,
    menuItems:
        [

          { title: "Client",
            icon: "mdi-account-circle",
            role: userRoles.ROLE_AMBASSADOR,
            items: [
              { title: 'Ajouter nouveau', modal: true, routeName: "/depart/creer", component: "NewCustomer", role: userRoles.ROLE_AMBASSADOR },
              { title: 'Rechercher', role: userRoles.ROLE_EMPLOYEE },
              { title: 'Paiements OM', modal: false, route: "/om/transactions", component: "OMPayments", role: userRoles.ROLE_AMBASSADOR },
            ]
          },
          { title: "Départ",
            icon: "mdi-car",
            role: userRoles.ROLE_EMPLOYEE,
            items: [
              { title: 'Ajouter nouveau', modal: true, routeName: "new_depart", component: "DepartureNew", role: userRoles.ROLE_ADMIN},
              { title: 'Départs En cours', modal: false, route: '/',component: "DepartsEvent", role: userRoles.ROLE_AMBASSADOR },
              { title: 'Autres Départs', modal: false, route: routes.EVENT_CURRENT_DEPARTS,component: "DepartsEvent", role: userRoles.ROLE_EMPLOYEE },
              { title: 'Gérer Points départ', modal: false, route: routes.POINTS_DEPART_LIST,component: "PointDepartsList", role: userRoles.ROLE_EMPLOYEE },
            ]
          },
          { title: "Évènements",
            icon: "mdi-calendar",
            role: userRoles.ROLE_ADMIN,
            items: [
              { title: 'Créer évènement', modal: true, route: "foo",component: "NewEvent", role:userRoles.ROLE_ADMIN },
              { title: 'Liste évènements', modal: false, route: routes.EVENT_LIST,component: "EventsList",role: userRoles.ROLE_ADMIN },
              { title: 'Statistiques', modal: false, route: "/stats",component: "EventsList",role: userRoles.ROLE_CEO },
            ]
          },
          { title: "Employés",
            icon: "mdi-account",
            role: userRoles.ROLE_CEO,
            items: [
              { title: 'Créer Employé', modal: true, route: "foo",component: "NewEmployee", role: userRoles.ROLE_CEO},
              { title: 'Liste des employés', modal: false, route: routes.EMPLOYEE_LIST,component: "EmployeeList",role: userRoles.ROLE_CEO },
              { title: 'Stats des employés', modal: false, route: routes.EMPLOYEE_STATS,component: "EmployeeStats",role: userRoles.ROLE_CEO },
            ]
          },
          { title: "Finances",
            icon: "mdi-chart-bar",
            role: userRoles.ROLE_SUPER_ADMIN,
            items: [
              { title: 'Etat des caisses', modal: false, route: "/finances",role: userRoles.ROLE_CEO },

            ]
          },
        ],

    searchValue: null,
    foundCustomer:{},
    currentBooking:{},
    modalToShowCustomerDetail: false,
    itemKey: 0,
  }),
  methods: {
    // subscribePusherChannels(){
    //   let pusher = new Pusher('998a53579acb62753141', { cluster: 'eu' })
    //   pusher.subscribe('bookings')
    //   pusher.bind('booking_added', data => {
    //     console.log(data)
    //    alert("new booking added")
    //   })
    // },
        ...mapActions('user_login',['logoutUser']),
    showBalanceOfUpcoming(){
      this.$axios.get("ticket_sales_upcoming")
          .then(response =>{
            this.balance = sum_array("total", response.getData())

          })
    },
    onMenuItemClicked(menuItem){
      this.itemKey++
      if (menuItem.modal) {
        this.componentToShow = menuItem.component
        this.modalToShowMenuContent = menuItem.modal;
      } else {
        this.$router.push(menuItem.route)

      }
    },
    findCustomer() {
      if (isValidPhoneNumber(this.searchValue)) {
        this.itemKey++
        this.modalToShowCustomerDetail = true
        this.$axios.get("clients/phone_number/:phoneNumber".replace(":phoneNumber", this.searchValue))
            .then(response => {
              this.foundCustomer = response.getData()
              this.currentBooking = this.foundCustomer.currentBooking
            })

      }
    },
    onDialogClose() {
      this.itemKey++
      this.modalToShowMenuContent = false
    },
    updatePassword() {
      this.$router.push("change_password");
    }
  },
  created() {
    // this.subscribePusherChannels()

    eventBus.$on('find_customer', (data) => {
      if (isValidPhoneNumber(data)){
        this.searchValue = data
        this.findCustomer()
      }
    })
  }
}
</script>

<style scoped>

</style>
