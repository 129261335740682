<template>
  <div>
    <v-data-table :items="departures" :headers="headers" no-results-text="Aucun départ" hide-default-footer hide-default-header
                  disable-pagination>
      <template v-slot:item.name="{item, index}">
        <v-menu :close-on-content-click="false" :key="index" v-if="isGranted('ROLE_EMPLOYEE')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="indigo"
                dark
                v-bind="attrs"
                v-on="on">
              mdi-plus
            </v-icon>
          </template>

          <v-card>
            <v-col v-for="(bus,index1) in item.buses" :key="index1">
              <v-chip
                  color="primary"
                  outlined
                  pill
                  :key="index1"
                  v-if="isGranted('ROLE_EMPLOYEE')"
              >
                {{ bus.name }}
                <template v-if="isGranted('ROLE_OWNER') || bus.shouldShowSeatNumbers">
                  <v-badge :content='bus.bookingsCount' inline dark color="error"></v-badge>
                  <v-badge :content='bus.ticketsSoldCount' inline dark color="success"></v-badge>

                </template>
                 <v-switch
                    v-model="bus.closed"
                    disabled
                    color="purple" :value="bus.closed"
                ></v-switch>
                <v-btn icon v-if="! bus.closed" :key="index1"
                       color="primary" @click.prevent="openNewBookingForm(item,bus)">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn icon class="blue-light" @click.prevent="showBookings(bus,item)" :key="index1">
                  <v-icon color="purple">
                    mdi-eye
                  </v-icon>
                </v-btn>
                <BusActions :bus='bus' :departure="item"/>
              </v-chip>

              <!--                              <NewBooking :bus='bus' :depart="item" @successful-booking="onBookingSuccess" />-->


            </v-col>

          </v-card>
        </v-menu>
        <span v-else>
            <v-btn icon v-if="! item.closed" text
                   color="primary" @click.prevent="openNewBookingForm(item,item.bus)">
              Réserver
            </v-btn>
          </span>
        {{ item.name }}


      </template>
      <template v-slot:item.actions="{item, index}" class="float-right">

        <DepartureItemActions class="float-right" v-if="isGranted('ROLE_EMPLOYEE')" :key="index"
                              @open-new-bus-form="openNewBusForm(item)" :depart="item"/>
      </template>
    </v-data-table>
    <v-dialog
        v-model="modal">
      <component :is="componentToShow" v-bind="componentToShow" :key="renderKey"/>
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="blue darken-1"
              text
              @click="modal = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
 </template>

<script>
import DepartureItemActions from "@/views/depart/DepartureItemActions";
import BusActions from "@/views/bus/BusActions";
import NewBooking from "@/views/booking/NewBooking";
import NewBus from "@/views/bus/NewBus";
import Bookings from "@/views/booking/Bookings";
import DepartureEdit from "@/views/depart/DepartureEdit";
export default {
name: "DepartsDataTable",
  components: {BusActions,
    DepartureEdit, NewBus,
    DepartureItemActions},
  props: {
    departures: {type: Array,
      required: true}
  },
  data() {
    return {
      componentToShow: null,
      renderKey: 0,
      modal: false,
      headers:[
        {text:"Départ", value: "name"},
        {text:"Actions", value: "actions"}
      ]
    }
  },
  methods: {
  openComponent(component){
    this.renderKey++
    this.modal = true
    this.componentToShow = component
  },
    openNewBookingForm(depart, bus){
    let componentToOpen = {
      is: NewBooking,
      bus: bus,
      depart: depart,
    }
      this.openComponent(componentToOpen)
    },
    openNewBusForm(depart){

      let comp = {
        is: NewBus,
        depart: depart
      }

      this.openComponent(comp)
    },
    showBookings(bus, depart) {

      let component = {
        is: Bookings,
        bus: bus,
        depart: depart,
      }
      this.openComponent(component)

    }
  },
}
</script>

<style scoped>

</style>
